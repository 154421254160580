import React from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from "prop-types"
import { navigate } from 'gatsby'

export default function ReviewScriptform({ industry, showPhone, phoneRequired, lb }) {
  const { register, handleSubmit, errors } = useForm()

  // Validate the industry and then decide if the dropdown will be shown or not.
  try {
    const valid_industries = [ "Automotive", "Dental", "Entertainment", "Food and Beverage", "In-Home", "Legal", "Medical", "Professional", "Real Estate", "Retail", "Other" ]
    if (!valid_industries.includes(industry) && industry !== null && industry !== '') {
      throw new Error('Industry selected does not exist! Form would be broken!')
    }
  } catch(e) {
    throw new Error('Bad industry passed to active campaign form!')
  }

  // Validate the lb type and set defaults
  try {
    const valid_lb = [ "general-info", "review-script", "covid-19-reviews-hidden" ]
    if (!valid_lb.includes(lb) && lb !== null && lb !== '') {
      throw new Error('LB selected does not exist! Form would be broken!')
    }
  } catch(e) {
    throw new Error('Bad LB passed to active campaign form!')
  }

  console.log(errors)

  const onSubmit = data => {
    try {
      //Need to rename a few fields to get this to work with activecampaign
      let preppedData = {'field[27]': data.industry, 'field[32]': data.lb, ...data}

      //Remove the old industry and lb (renamed above)
      const { industry, lb, ...cleaned  } = preppedData

      //Convert to FormData
      let form_data = new FormData()
      for ( let key in cleaned ) {
        form_data.append(key, cleaned[key])
      }

      fetch('https://essentialmarketingsystems.activehosted.com/proc.php', {
        method: 'POST',
        mode: 'no-cors',
        cache: 'no-cache',
        body: form_data,
      })
      localStorage.setItem('ACmail', btoa(cleaned.email));
      navigate('/m/heroes-guide/super-hero-strategy')
    } catch (error) {
      // handle server errors
      console.log("Request failed", error)
    }
  }

  const showForm = (
    <form onSubmit={handleSubmit(onSubmit)} method="post" class="signup-form px-5 py-4" style={{background: "linear-gradient(#2f437e, #0b132a)"}}>
      <input type="hidden" name="u" value="1059" ref={register({ required: true })}/>
      <input type="hidden" name="f" value="1059" ref={register({ required: true })}/>
      <input type="hidden" name="s" ref={register({ })}/>
      <input type="hidden" name="c" value="0" ref={register({ required: true })}/>
      <input type="hidden" name="m" value="0" ref={register({ required: true })}/>
      <input type="hidden" name="act" value="sub" ref={register({ required: true })}/>
      <input type="hidden" name="v" value="2" ref={register({ required: true })}/>

      <input type="hidden" name="lb" value={lb} ref={register({ required: true })}/>






      <div class="form-field form-group mx-auto">
        <input className="form-control" type="text" placeholder="Name" name="fullname" ref={register({required: true, minLength: 2, maxLength: 80})} />
        {errors.fullname && <div className="text-warning mt-1">Please enter your name.</div>}
      </div>

      <div className="form-field form-group mx-auto">
        <input className="form-control" type="text" placeholder="Email" name="email" ref={register({required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
        {errors.email && <div className="text-warning mt-1">Please enter your email address.</div>}
      </div>

      {showPhone && (
        <div className="form-field form-group mx-auto">
          <input className="form-control " type="tel" placeholder="Phone number" name="phone" ref={register({required: phoneRequired, minLength: 10, maxLength: 12})} />
          {phoneRequired && errors.phone && <div className="text-warning mt-1">Please enter your phone number.</div>}
        </div>
      )}


      {(industry === null || industry === '') ?
        <div className="form-field mx-auto">
          <select className="form-control" name='industry' ref={register({ required: true })}>
            <option value="">Industry</option>
            <option value="Automotive">Automotive</option>
            <option value="Dental">Dental</option>
            <option value="Entertainment">Entertainment</option>
            <option value="Food and Beverage">Food and Beverage</option>
            <option value="In-Home">In-Home</option>
            <option value="Legal">Legal</option>
            <option value="Medical">Medical</option>
            <option value="Professional">Professional</option>
            <option value="Real Estate">Real Estate</option>
            <option value="Retail">Retail</option>
            <option value="Other">Other</option>
          </select>
          {(industry === null || industry === '') && errors.industry && <div className="text-warning mt-1">Please select an industry.</div>}
        </div>:
        <input type="hidden" name="industry" value={industry} ref={register({ required: true })}/>
      }


      <div className="form-field form-group mx-auto pt-3">
        <input className="btn btn-primary" type="submit" />
      </div>
    </form>
  )

  return (
    <div>
      {showForm}
    </div>
  )
}

ReviewScriptform.propTypes = {
  industry: PropTypes.string,
  showPhone: PropTypes.bool,
  phoneRequired: PropTypes.bool,
  lb: PropTypes.string,
}

ReviewScriptform.defaultProps = {
  industry: null,
  showPhone: false,
  phoneRequired: false,
  lb: 'general-info',
}